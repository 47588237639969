import { Fragment } from "react";

import { ImageLoading, ImageTypes, getUrlForAssets } from "../../Contracts";

export const Image = (props) => {

  const { src, fallbackSrc, alt = 'default', height = '100%', width = '100%', loading = ImageLoading.LAZY } = props;

  if (!src || src.length === 0) {
    return null;
  }

  const getFileExtension = (imgSrc) => {
    try {
      const url = new URL(imgSrc);
      return url.pathname.split('.').pop();
    } catch (error) {
      // URL constructor conversion will fail for relative URL's
      return imgSrc.split('.').pop();
    }
  };

  const getImageType = (imgSrc) => {
    const fileExtension = getFileExtension(imgSrc);
    return `image/${fileExtension}`;
  };

  const renderImageSourceTag = ({ srcSet, type }) => {
    return (
      <source srcSet={getUrlForAssets(srcSet)} type={type} className={props.pictureClassName ? props.pictureClassName : ''} />
    );
  };

  const renderImageTag = () => {
    const imgProps = { ...props };
    delete imgProps.fallbackSrc;
    return (
      <img src={getUrlForAssets(src)} alt={alt} height={height} width={width} loading={loading} {...imgProps} id={props.id} className={props.className ? props.className : ''} style={props.style ? props.style : {}} />
    );
  };

  // NOTE: If image url is webp then try to render webp inside picture tag
  // If webp image failed then we will try to render fallback if available

  return (
    <picture style={props.style ? props.style : {}}>
      {getFileExtension(src) === ImageTypes.WEBP && (
        <Fragment>
          {renderImageSourceTag({ srcSet: src, type: getImageType(src) })}
          {fallbackSrc && renderImageSourceTag({ srcSet: fallbackSrc, type: getImageType(fallbackSrc) })}
        </Fragment>
      )}
      {renderImageTag(src)}
    </picture>
  );

};

export default Image;
