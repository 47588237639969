import { useEffect, useState } from "react";

import { Position, Orientation, TypographyVariants, ImageLoading, getMergedQueryParamsUrl, getUrlForAssets } from "../../Contracts";

import RightArrow from "../../Common/Icons/RightArrow";

import Typography from "../Typography";

import styles from "./PageLinks.module.scss";

export const PageLinks = (props) => {

  const [browserWindow, setBrowserWindow] = useState({});

  useEffect(() => {
    setBrowserWindow(window);
  }, []);

  if (!props.links || props.links.length === 0) {
    return null;
  }

  const renderLinkText = (text, color) => {
    if (!text) {
      return null;
    }
    const linkTextStyle = color ? { color, borderColor: color } : {};
    return (
      <Typography
        variant={TypographyVariants.SPAN}
        className={`${styles.LinkText} ${props.linkTextClassName ? props.linkTextClassName : ''}`}
        style={{ fontSize: '18px', ...linkTextStyle }}
        tabletStyle={{ fontSize: '17px' }}
        mobileStyle={{ fontSize: '16px' }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };

  const renderIcon = (IconComponent, color) => {
    const linkIconClassName = `${styles.LinkIcon} ${props.linkIconClassName ? props.linkIconClassName : ''}`;
    if (IconComponent) {
      return (
        <IconComponent className={linkIconClassName} fill={color ? color : undefined} style={{ fill: color ? color : undefined }} />
      );
    }
    return (
      <RightArrow className={linkIconClassName} fill={color ? color : undefined} style={{ fill: color ? color : undefined }} />
    );
  };

  const renderExtraIcon = (link) => {
    if (!link) {
      return null;
    }
    const extraIconClassName = `${styles.ExtraIcon} ${link.extraIconPosition === Position.LEFT ? styles.ExtraIconLeft : styles.ExtraIconRight} ${props.extraIconClassName ? props.extraIconClassName : ''}`;
    return (
      <img src={getUrlForAssets(link.extraIconUrl)} alt="extra icon" className={extraIconClassName} loading={ImageLoading.LAZY} height="100%" width="100%" />
    );
  };

  const getLinkClick = () => {
    const { onClick } = props;
    if (typeof onClick === 'string' && browserWindow[onClick]) {
      return browserWindow[onClick];
    } else if (typeof onClick === 'function') {
      return onClick;
    }
    return undefined;
  };

  const getLinkStyle = (link) => {
    if (!link) {
      return {};
    }
    const colorStyle = link.color ? { color: link.color, borderColor: link.color, fill: link.color } : {};
    return props.style ? { ...props.style, colorStyle } : colorStyle;
  };

  const renderLink = (link) => {
    if (!link) {
      return null;
    }
    const style = getLinkStyle(link);
    return (
      <a
        href={getMergedQueryParamsUrl(link.url, browserWindow)}
        target={link.openInNewTab ? '_blank' : '_self'}
        rel={link.openInNewTab ? 'noopener noreferrer' : ''}
        id={props.id}
        style={style}
        onClick={getLinkClick()}
        className={`${styles.Link} ${props.linkClassName ? props.linkClassName : ''} ${link.iconPosition === Position.LEFT ? styles.LinkIconLeft : styles.LinkIconRight} ${props.hideUnderline ? styles.HideUnderline : styles.ShowUnderline}`}
        itemProp="url"
        title={link.title ? link.title : (link.identifier_internal ? link.identifier_internal : undefined)}
        aria-label={link.title ? link.title : (link.identifier_internal ? link.identifier_internal : undefined)}
      >
        {link.text && renderLinkText(link.text, link.color)}
        {link.showLinkIcon && renderIcon(link.icon, link.color)}
      </a>
    );
  };

  const renderWithoutLink = (link) => {
    const style = getLinkStyle(link);
    return (
      <li
        id={props.id}
        style={style}
        className={`${styles.Link} ${props.linkClassName ? props.linkClassName : ''} ${link.iconPosition === Position.LEFT ? styles.LinkIconLeft : styles.LinkIconRight} ${(props.hideUnderline || link.hideUnderline) ? styles.HideUnderline : styles.ShowUnderline}`}
      >
        {link.text && renderLinkText(link.text, link.color)}
        {link.showLinkIcon && renderIcon(link.icon, link.color)}
      </li>
    );
  };

  return (
    <ul
      className={`${styles.LinksContainer} ${props.className ? props.className : ''} ${props.orientation === Orientation.HORIZONTAL ? styles.LinksContainerHorizontal : styles.LinksContainerVertical}`}
      id={props.id}
      style={props.style ? props.style : {}}
      itemType="http://www.schema.org/SiteNavigationElement"
      itemScope={true}
    >
      {props.links.map((link, index) => {
        return link.url ? (
          <li key={index} className={styles.LinkContainer} itemProp="name">
            {(link.showExtraIcon && link.extraIconUrl) && (
              renderExtraIcon(link)
            )}
            {renderLink(link, index)}
          </li>
        ) : (
          <li key={index} className={styles.LinkContainer} itemProp="name">
            {(link.showExtraIcon && link.extraIconUrl) && (
              renderExtraIcon(link)
            )}
            {renderWithoutLink(link, index)}
          </li>
        );
      })}
    </ul>
  );

};

export default PageLinks;
