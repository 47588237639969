import { Fragment } from "react";

import { TypographyVariants } from "../../../Contracts";

import Typography from "../../Typography";
import PageLinks from "../../PageLinks";

import styles from './HeaderUseCasesSubMenu.module.scss';

export const HeaderUseCasesSubMenu = ({ headerUseCasesSubMenuData }) => {

  if (!headerUseCasesSubMenuData) {
    return null;
  }

  const {
    browseByBusinessModelSection = {},
    browseByindustrySection = {},
    keyFeaturesSection = {}
  } = headerUseCasesSubMenuData;

  return (
    <div className={styles.Container}>
      <section className={styles.Row}>
        <aside className={styles.Column}>
          {browseByBusinessModelSection.cardTitle && (
            <Typography
              variant={TypographyVariants.HEADING_2}
              style={{ fontSize: '18px', fontWeight: '700', color: 'var(--black)' }}
              tabletStyle={{ fontSize: '17px' }}
              mobileStyle={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{ __html: browseByBusinessModelSection.cardTitle }}
            />
          )}
          {browseByBusinessModelSection.featuresList && browseByBusinessModelSection.featuresList.length > 0 && browseByBusinessModelSection.featuresList.map((feature, index) => {
            const { cardTitle, cardDescription, linksList = [] } = feature;
            return (
              <Fragment key={index}>
                {cardTitle && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    style={{ fontSize: '18px', fontWeight: '700', color: 'var(--green)', marginTop: index === 0 ? '12px' : '18px' }}
                    tabletStyle={{ fontSize: '17px' }}
                    mobileStyle={{ fontSize: '16px' }}
                    dangerouslySetInnerHTML={{ __html: cardTitle }}
                  />
                )}
                {cardDescription && (
                  <Typography
                    variant={TypographyVariants.HEADING_3}
                    style={{ fontSize: '16px', fontWeight: '400', color: 'var(--black)', marginTop: '12px' }}
                    tabletStyle={{ fontSize: '15px', marginTop: '12px' }}
                    mobileStyle={{ fontSize: '14px', marginTop: '12px' }}
                    dangerouslySetInnerHTML={{ __html: cardDescription }}
                  />
                )}
                {linksList.length > 0 && (
                  <PageLinks links={linksList} className={styles.PageLinks} />
                )}
              </Fragment>
            );
          })}
        </aside>
        <aside className={styles.Column}>
          {browseByindustrySection.cardTitle && (
            <Typography
              variant={TypographyVariants.HEADING_2}
              style={{ fontSize: '18px', fontWeight: '700', color: 'var(--black)' }}
              tabletStyle={{ fontSize: '17px' }}
              mobileStyle={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{ __html: browseByindustrySection.cardTitle }}
            />
          )}
          {browseByindustrySection.linksList && browseByindustrySection.linksList.length > 0 && (
            <PageLinks links={browseByindustrySection.linksList} className={styles.PageLinks} />
          )}
        </aside>
        <aside className={styles.Column}>
          {keyFeaturesSection.cardTitle && (
            <Typography
              variant={TypographyVariants.HEADING_2}
              style={{ fontSize: '18px', fontWeight: '700', color: 'var(--black)' }}
              tabletStyle={{ fontSize: '17px' }}
              mobileStyle={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{ __html: keyFeaturesSection.cardTitle }}
            />
          )}
          {keyFeaturesSection.linksList && keyFeaturesSection.linksList.length > 0 && (
            <PageLinks links={keyFeaturesSection.linksList} className={styles.PageLinks} />
          )}
        </aside>
      </section>
    </div>
  );
};

export default HeaderUseCasesSubMenu;
