import { useRef, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

import { HeaderMenuLinkHash, ImageLoading, getMergedQueryParamsUrl, capitalize, getUrlForAssets } from '../../Contracts';

import Button from '../Button';

import HeaderUseCasesSubMenu from './HeaderUseCasesSubMenu';

import styles from "./Header.module.scss";

export const Header = (props) => {

  const {
    headerData = {},
    headerUseCasesSubMenuData = {},
    geoIp = {},
    showB2bEcommerceMenu = false
  } = props;

  const headerElementRef = useRef(null);

  const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
  const [subMenuDropdownVisible, setSubMenuDropdownVisible] = useState(null);
  const [browserWindow, setBrowserWindow] = useState({});
  const [windowScroll, setWindowScroll] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const openMobileMenu = () => {
    setMenuDropdownVisible(true);
    try {
      browserWindow.history.pushState({ menuId: HeaderMenuLinkHash.MENU }, document.title, browserWindow.location.href);
    } catch (error) {
      // Invalid url or maybe window isn't initialized yet
    }
  };

  const closeMobileMenu = () => {
    setMenuDropdownVisible(false);
    setSubMenuDropdownVisible(null);
    if (browserWindow.history && browserWindow.history.state && browserWindow.history.state.menuId === HeaderMenuLinkHash.MENU && typeof browserWindow.history.back === 'function') {
      browserWindow.history.back();
    }
  };

  const getPopupContent = (menuItem) => {
    if (!menuItem || !menuItem.menuId) {
      return null;
    }
    switch (menuItem.menuId) {
      case HeaderMenuLinkHash.USE_CASES: {
        return <HeaderUseCasesSubMenu headerUseCasesSubMenuData={headerUseCasesSubMenuData} geoIp={geoIp} />;
      }
      default: {
        return null;
      }
    }
  };

  const setHeaderShadow = () => {
    if (headerElementRef.current) {
      if (windowScroll > 0) {
        headerElementRef.current.classList.add(styles.HeaderFixed, 'quicksell-header-fixed');
      } else {
        headerElementRef.current.classList.remove(styles.HeaderFixed, 'quicksell-header-fixed');
      }
    }
  };

  const handleScrollEvent = debounce(() => {
    const body = document.querySelector('.quicksell-page');
    if (body) {
      setWindowScroll(body.scrollTop);
    }
  }, 0);

  const handleResizeEvent = debounce((event) => {
    if (event && event.type === 'resize') {
      setWindowWidth(event.target.innerWidth);
    }
  }, 200);

  const handlePopStateEvent = () => {
    closeMobileMenu();
  };

  useEffect(() => {
    setBrowserWindow(window);
    setWindowWidth(window.innerWidth);
    const body = document.querySelector('.quicksell-page');
    if (body) {
      body.addEventListener('scroll', handleScrollEvent);
    }
    window.addEventListener('resize', handleResizeEvent);
    window.addEventListener('popstate', handlePopStateEvent);
    return () => {
      if (body) {
        body.removeEventListener('scroll', handleScrollEvent);
      }
      window.removeEventListener('resize', handleResizeEvent);
      window.removeEventListener('popstate', handlePopStateEvent);
    };
  }, []);

  useEffect(() => {
    if (headerElementRef.current) {
      if (menuDropdownVisible) {
        headerElementRef.current.classList.add(styles.HeaderMenuOpen);
      } else {
        headerElementRef.current.classList.remove(styles.HeaderMenuOpen);
      }
    }
  }, [menuDropdownVisible]);

  useEffect(() => {
    setHeaderShadow();
  }, [windowScroll]);

  const toggleSubMenuDropdownVisible = (menuItem) => {
    if (menuItem && menuItem.menuId) {
      setSubMenuDropdownVisible(subMenuDropdownVisible ? null : menuItem.menuId);
    }
  };

  const renderBrandLogoImage = (brandSection) => {
    return (brandSection.cardImageUrl) && (
      <img src={getUrlForAssets(brandSection.cardImageUrl)} alt="brand logo" className={styles.HeaderLogoImage} loading={ImageLoading.EAGER} height={brandSection.cardImageHeight || "100%"} width={brandSection.cardImageWidth || "100%"} />
    );
  };

  const renderBrandLogo = (brandSection) => {
    return (brandSection.linkUrl) ? (
      <a href={getMergedQueryParamsUrl(brandSection.linkUrl, browserWindow)} target={brandSection.openInNewTab ? '_blank' : '_self'} rel={brandSection.openInNewTab ? 'noopener noreferrer' : ''} className={styles.HeaderLogoLink}>
        {renderBrandLogoImage(brandSection)}
      </a>
    ) : renderBrandLogoImage(brandSection);
  };

  const renderMenuItemLinkText = (menuItem) => {
    if (!menuItem || !menuItem.text) {
      return null;
    }
    return (
      <span
        className={styles.HeaderMenuLinkText}
        style={{ color: menuItem.color ? menuItem.color : 'inherit', fontWeight: menuItem.bold ? '700' : '400' }}
        dangerouslySetInnerHTML={{ __html: capitalize(menuItem.text) }}
      />
    );
  };

  const renderMenuIcon = () => {
    return (
      <div className={styles.HeaderMenuIconContainer} onClick={menuDropdownVisible ? closeMobileMenu : openMobileMenu}>
        <div className={`${styles.HeaderMenuIcon} ${menuDropdownVisible ? styles.HeaderMenuIconOpen : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  const renderSubMenuDropdownIcon = () => {
    return (
      <svg focusable={false} width="10" height="6" viewBox="0 0 10 6" fill="none" className={styles.HeaderSubMenuDropdownIcon}>
        <path d="M1 1l4 4 4-4" stroke="var(--black)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  };

  const renderMenuItem = (menuItem, index, isMobileMenuItem) => {
    if (!menuItem || (!menuItem.url && !menuItem.menuId)) {
      return null;
    }
    const isDesktop = windowWidth >= parseFloat(styles['variables-desktopMediaQueryMinWidth']);
    const popupContent = menuItem.menuId ? getPopupContent(menuItem) : null;
    const menuItemClick = () => {
      toggleSubMenuDropdownVisible(menuItem);
    };
    const menuLinkClick = (event) => {
      if (menuItem.menuId) {
        event.preventDefault();
      }
      if (typeof menuItem.onClick === 'string' && browserWindow[menuItem.onClick]) {
        browserWindow[menuItem.onClick]();
      } else if (typeof menuItem.onClick === 'function') {
        menuItem.onClick();
      }
    };
    const onMouseEnter = () => {
      if (menuItem.menuId) {
        setSubMenuDropdownVisible(menuItem.menuId);
        if (headerElementRef.current && windowScroll === 0) {
          headerElementRef.current.classList.add(styles.HeaderFixed, 'quicksell-header-fixed');
        }
      }
    };
    const onMouseLeave = () => {
      if (menuItem.menuId) {
        setSubMenuDropdownVisible(null);
        if (headerElementRef.current && windowScroll === 0) {
          headerElementRef.current.classList.remove(styles.HeaderFixed, 'quicksell-header-fixed');
        }
      }
    };
    return (
      <li
        key={index}
        className={`${styles.HeaderMenuItemContainer} ${popupContent ? styles.HeaderSubMenuItemContainer : ''} ${(subMenuDropdownVisible === menuItem.menuId && popupContent) ? styles.HeaderSubMenuDropdownOpen : ''}`}
        onMouseEnter={(isDesktop && menuItem.menuId) ? onMouseEnter : undefined}
        onMouseLeave={(isDesktop && menuItem.menuId) ? onMouseLeave : undefined}
        itemProp="name"
      >
        <div
          className={styles.HeaderMenuItem}
          onClick={(isMobileMenuItem && popupContent) ? menuItemClick : undefined}
        >
          <a
            href={menuItem.menuId ? `#${menuItem.menuId}` : getMergedQueryParamsUrl(menuItem.url, browserWindow)}
            target={menuItem.openInNewTab ? '_blank' : '_self'}
            rel={menuItem.openInNewTab ? 'noopener noreferrer' : ''}
            className={styles.HeaderMenuLink}
            onClick={menuLinkClick}
            itemProp="url"
          >
            {renderMenuItemLinkText(menuItem)}
          </a>
          {popupContent && renderSubMenuDropdownIcon(isMobileMenuItem)}
        </div>
        {(subMenuDropdownVisible === menuItem.menuId && popupContent) && (
          <div className={styles.HeaderSubMenuDropdown}>
            {popupContent}
          </div>
        )}
      </li>
    );
  };

  const renderBookDemoLink = (button, index) => {
    const {text, onClick} = button;

    return (
      <li
        key={index}
        className={`${styles.HeaderMenuItemContainer}`}
        itemProp="name"
      >
        <Button
          className={styles.HeaderMenuBookDemoButton}
          onClick={onClick}
        >
          {text}
        </Button>
      </li>
    );
  }

  const renderMenu = (menuSection) => {
    const { linksList = [], buttonsList = [] } = menuSection;
    const { geoIp = {} } = props;
    if (!geoIp.countryCode) {
      return null;
    }
    if (geoIp.countryCode === 'IN') {
      const pricingMenuItemIndex = linksList.findIndex((menuItem) => menuItem.text.toLowerCase() === 'pricing');
      if (pricingMenuItemIndex > -1) {
        linksList.splice(pricingMenuItemIndex, 1);
      }
    }
    return (
      <nav className={styles.HeaderMenu}>
        {windowWidth >=
          parseFloat(styles["variables-desktopMediaQueryMinWidth"]) && (
          <ul
            className={styles.HeaderMenuItemList}
            itemType="http://www.schema.org/SiteNavigationElement"
            itemScope={true}
          >
            {linksList.map((menuItem, index) => {
              return renderMenuItem(menuItem, index);
            })}
            {showB2bEcommerceMenu &&
              buttonsList.map((button, index) => {
                return renderBookDemoLink(button, index);
              })}
          </ul>
        )}
        {windowWidth <
          parseFloat(styles["variables-desktopMediaQueryMinWidth"]) &&
          renderMenuIcon()}
      </nav>
    );
  };

  const renderMobileMenu = (menuSection) => {
    const { buttonsList = [], linksList = [] } = menuSection;
    if (buttonsList.length === 0 && linksList.length === 0) {
      return null;
    }
    return (
      <nav className={styles.HeaderMobileMenu}>
        {linksList.length > 0 && (
          <ul className={styles.HeaderMenuItemList}>
            {linksList.map((menuItem, index) => {
              return renderMenuItem(menuItem, index, true);
            })}
          </ul>
        )}
        {buttonsList.length > 0 && (
          <div
            className={`${
              showB2bEcommerceMenu
                ? styles.HeaderMobileMenuButtonContainerB2bEcommerce
                : styles.HeaderMobileMenuButtonsContainer
            }`}
          >
            {buttonsList.map((button, index) => {
              const { type, htmlType, linkUrl, openInNewTab, text, onClick } =
                button;
              return (
                <Button
                  key={index}
                  type={type}
                  htmlType={htmlType}
                  className={styles.HeaderMobileMenuButton}
                  linkUrl={linkUrl}
                  openInNewTab={openInNewTab}
                  onClick={onClick}
                >
                  {text}
                </Button>
              );
            })}
          </div>
        )}
      </nav>
    );
  };

  return (
    <header
      className={`${styles.HeaderContainer} quicksell-header`}
      ref={headerElementRef}
    >
      <div className={styles.Header}>
        {headerData.brandSection && (
          <div className={styles.HeaderLogo}>
            {renderBrandLogo(headerData.brandSection)}
          </div>
        )}
        {showB2bEcommerceMenu && headerData.b2bECommerceMenuSection
          ? renderMenu(headerData.b2bECommerceMenuSection)
          : headerData.menuSection && renderMenu(headerData.menuSection)}
      </div>
      {headerData.menuSection &&
      windowWidth < parseFloat(styles["variables-desktopMediaQueryMinWidth"]) &&
      showB2bEcommerceMenu &&
      headerData.b2bECommerceMenuSection
        ? renderMobileMenu(headerData.b2bECommerceMenuSection)
        : headerData.menuSection && renderMobileMenu(headerData.menuSection)}
    </header>
  );

};

export default Header;
